<template>
  <md-toolbar
    md-elevation="0"
    class="md-transparent"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
    }"
  >
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">{{ $route.name }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-round md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <md-list>
            <!--<li class="md-list-item">
              <a
                href="#/components/notifications"
                class="
                  md-list-item-router md-list-item-container md-button-clean
                  dropdown
                "
              >
                <div class="md-list-item-content">
                  <drop-down direction="down">
                    <md-button
                      slot="title"
                      class="md-button md-just-icon md-simple"
                      data-toggle="dropdown"
                    >
                      <md-icon>notifications</md-icon>
                      <span class="notification">5</span>
                      <p class="hidden-lg hidden-md">Notifications</p>
                    </md-button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li><a href="#">Mike John responded to your email</a></li>
                      <li><a href="#">You have 5 new tasks</a></li>
                      <li><a href="#">You're now friend with Andrew</a></li>
                      <li><a href="#">Another Notification</a></li>
                      <li><a href="#">Another One</a></li>
                    </ul>
                  </drop-down>
                </div>
              </a>
            </li>-->
            <li>
              <a href="javascript:void(0)" @click="androidAPKModal = true" class="md-button md-info">
                <div class="md-ripple">
                  <div class="md-button-content"><md-icon>adb</md-icon> Download APK</div>
                </div>
              </a>
            </li>&nbsp;
            <li>
              <a href="javascript:void(0)" @click="$router.back()" class="md-button md-warning">
                <div class="md-ripple">
                  <div class="md-button-content">Back</div>
                </div>
              </a>
            </li>&nbsp;
            <li v-if="loginUserDetail.isOfflineCustomer != true">
              <a @click="$router.push('plans')" class="md-button md-primary">
                <div class="md-ripple">
                <div class="md-button-content">buy Plan</div>
                </div>
              </a>
            </li>
            <li class="md-list-item">
              <a href="javascript:void(0)" class="md-list-item-router md-list-item-container md-button-clean dropdown">
                <div class="md-list-item-content">
                  <drop-down direction="down">
                    <md-button slot="title" class="md-button md-just-icon md-simple" data-toggle="dropdown">
                      <!--<h4>Divyesh Amreliya</h4>&nbsp;-->
                      <md-icon>group</md-icon>
                    </md-button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li><a @click="$router.push({ path: 'payment-history'})">Payment History</a></li>
                      <li><a @click="$router.push({ path: 'change-password'})">Change Password</a></li>
                      <li><a @click="$router.push({ path: '/payment-methods'})">Payment Methods</a></li>
                      <li><a @click="$router.push({ path: '/privacy-security'})">Privacy & Security</a></li>
                      <li><a @click="DoLogout()">Logout</a></li>
                    </ul>
                  </drop-down>
                </div>
              </a>
            </li>
            <!--<li>
              <a href="https://docs.tabnova.com/novaemm" target="_blank" class="md-button md-primary">
                <div class="md-ripple">
                <div class="md-button-content">Documentation</div>
                </div>
              </a>
            </li>&nbsp;
            <li>
                <a @click="DoLogout()" class="md-button md-secondary">
                  <div class="md-ripple">
                  <div class="md-button-content">Logout</div>
                  </div>
                </a>
              </li>-->
          </md-list>
        </div>
      </div>
    </div>
    <div class="md-toolbar-row text-center justify-content-center">
      <div class="alert alert-danger" style="width: 70%;" v-if="deviceExceededMessage">
        <span :style="{ 'max-width': '100%' }"><b> Danger - </b> {{ deviceExceededMessage }}
        <a href="javascript:void(0)" @click="$router.push('plans')"><b>Click Here</b></a> 
        buy more licenses</span>
      </div>
    </div>

    <template>
        <modal v-if="androidAPKModal" @close="androidAPKModal = false" class="large-modal">
          <template slot="header">
            <h4 class="modal-title">Download Android APK</h4>
            <md-button
              style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
              @click="androidAPKModal = false"><md-icon>clear</md-icon></md-button>
          </template>
          <template slot="body">
            <nav-tabs-card>
              <template slot="content">
                <md-tabs class="md-rose" md-alignment="left">
                  <md-tab id="tab-home" md-label="Package Disabler" v-if="pdPriceDetail">
                      <div style="width: 50%;">
                        <img class="card-img-top" :src="pdPriceDetail.apkLinkQrCode" alt="Card image cap">
                        <a :href="pdPriceDetail.apkLink"><small><b>Click Here to Download APK</b></small></a> 
                      </div>
                  </md-tab>
                  <md-tab id="tab-pc" md-label="Power Cleaner" v-if="pcPriceDetail">
                    <div style="width: 50%;">
                      <img class="card-img-top" :src="pcPriceDetail.apkLinkQrCode" alt="APK QR Code">
                      <a :href="pcPriceDetail.apkLink"><small><b>Click Here to Download APK</b></small></a> 
                    </div> 
                  </md-tab>
                  <md-tab id="tab-km" md-label="Key Mapper" v-if="kmPriceDetail">
                    <div style="width: 50%;">
                      <img class="card-img-top" :src="kmPriceDetail.apkLinkQrCode" alt="APK QR Code">
                      <a :href="kmPriceDetail.apkLink"><small><b>Click Here to Download APK</b></small></a> 
                    </div>
                  </md-tab>
                  <md-tab id="tab-ub" md-label="URL Blocker" v-if="ubPriceDetail">
                    <div style="width: 50%;">
                      <img class="card-img-top" :src="ubPriceDetail.apkLinkQrCode" alt="APK QR Code">
                      <a :href="ubPriceDetail.apkLink"><small><b>Click Here to Download APK</b></small></a> 
                    </div>
                  </md-tab>
                  <md-tab id="tab-pob" md-label="Power on Boot" v-if="pobPriceDetail">
                    <div style="width: 50%;">
                      <img class="card-img-top" :src="pobPriceDetail.apkLinkQrCode" alt="APK QR Code">
                      <a :href="pobPriceDetail.apkLink"><small><b>Click Here to Download APK</b></small></a> 
                    </div>
                  </md-tab>
                  <md-tab id="tab-ub" md-label="Power Chat" v-if="powerChatPriceDetail">
                    <div style="width: 50%;">
                      <img class="card-img-top" :src="powerChatPriceDetail.apkLinkQrCode" alt="APK QR Code">
                      <a :href="powerChatPriceDetail.apkLink"><small><b>Click Here to Download APK</b></small></a> 
                    </div>
                  </md-tab>
                </md-tabs>
              </template>
            </nav-tabs-card>
          </template>
          <template slot="footer">
            <md-button class="md-danger md-simple" @click="androidAPKModal = false">Close</md-button>
          </template>
        </modal>
    </template>
  </md-toolbar>
</template>

<script>
import UserDataService from "../../../services/UsersDataService";
import PaymentService from "../../../services/Customer/PaymentService";
import { Modal } from "@/components";
import { Tabs,NavTabsCard } from "@/components";
export default {
  components: {
    Modal,
    NavTabsCard
    //Tabs,
  },
  data() {
    return {
      
      selectedEmployee: "",
      deviceExceededMessage:"",
      androidAPKModal: false,
      pdPriceDetail: null,
      pcPriceDetail: null,
      ubPriceDetail: null,
      kmPriceDetail: null,
      powerChatPriceDetail: null,
      pobPriceDetail:null,
      loginUserDetail: null,
      employees: [
        "Jim Halpert",
        "Dwight Schrute",
        "Michael Scott",
        "Pam Beesly",
        "Angela Martin",
        "Kelly Kapoor",
        "Ryan Howard",
        "Kevin Malone",
      ],
    };
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    DoLogout() {
      localStorage.setItem('token', "");
      UserDataService.Logout()
        .then((response) => {})
        .catch((e) => {
        });
      this.$router.push('/login');
    },
    GetAdminDetail() {
      var data = {};
      UserDataService.getUserDetail(data)
        .then((response) => {
            this.loginUserDetail = response.data.data;
            if (response.data.devices.enrolledDevices > response.data.devices.purchasedDevices) {
              this.deviceExceededMessage = "Your enrolled licenses exceed then available licenses."    
            }
            this.deviceExceededMessage = "Your enrolled licenses exceed then available licenses."    
        })
        .catch((e) => {
          if (e.response.data.is_logout == 1) {
            localStorage.setItem('token', "");
            this.$router.push('login');
          }
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    downloadSdkList() {
      var data = { appName : "PD"};
      UserDataService.downloadSdkList(data)
        .then((response) => {
            this.pdPriceDetail = response.data.pdPriceDetail
            this.pcPriceDetail = response.data.pcPriceDetail;
            this.ubPriceDetail = response.data.ubPriceDetail;
            this.kmPriceDetail = response.data.kmPriceDetail;
            this.pobPriceDetail = response.data.pobPriceDetail;
            this.powerChatPriceDetail = response.data.powerChatPriceDetail;
        })
        .catch((e) => {
          if (e.response.data.is_logout == 1) {
            localStorage.setItem('token', "");
            this.$router.push('login');
          }
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount(){
    this.GetAdminDetail();
    this.downloadSdkList();
  },
};
</script>
<style>
.md-tabs-navigation {
  padding: unset !important;
  margin: -52px -15px 0px -62px !important
}
</style>